<template>
  <div class="goldenSentenceIndex">
    <!-- 列表 -->
    <div class="goldenSentenceIndex-list">
      <!-- 排序 -->
      <div class="list-title">
        <!-- <SelectNotes id="LangeSelectNotes" :options="options" /> -->
        <div
          v-show="!$parent.isMark"
          class="list-title-item"
          v-for="(item, index) in sortArr"
          :key="index"
          style="cursor: pointer"
          @click="sortClick(item)"
        >
          <div
            class="list-title-item-text"
            :class="item.colorSwitch == true ? 'fontColor' : ''"
          >
            {{ item.title }}
          </div>
          <i
            class="list-title-icon up"
            :class="item.sort == 'asc' ? 'up' : 'down'"
          ></i>
        </div>
      </div>
      <div class="list-content">
        <div
          class="list-content-item"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="list-content-item-up">
            <!-- <i class="list-content-item-add" @click="handleCopyName(item)"></i> -->
            <i
              class="list-content-item-copy"
              @click="
                () => {
                  copyFileName(item.nodeText);
                  addHotRecord(item.textMd5);
                }
              "
            ></i>
            <div class="list-content-title">
              {{ index + 1 }}.
              <span style="word-break: normal" v-html="item.nodeText"></span>

              <img
                class="list-content-collection"
                @click.stop="collection(item)"
                :src="
                  item.isMark
                    ? require('assets/images/goldenSentence/collection-red.png')
                    : require('assets/images/goldenSentence/collection.png')
                "
                alt=""
              />
              <!-- <i
                class="list-content-collection"
                :class="item.collected == 1 ? 'collection' : ''"
                @click="collection(item)"
              ></i> -->
            </div>
          </div>
          <div class="list-content-item-down" v-if="item.footNotes">
            <i
              class="list-content-item-down-icon"
              @click="
                () => {
                  addHotRecord(item.textMd5);
                  copyFileName(item.footNotes);
                }
              "
            ></i>
            <div class="list-content-item-down-text">{{ item.footNotes }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="list-kong" v-else>未搜索到数据</div> -->
    </div>

    <!-- 分页 -->
    <div class="footer-paging">
      <el-pagination
        v-if="listData.length != 0 && showPage"
        layout="prev, pager, next"
        :pager-count="5"
        :current-page="$parent.page"
        :page-size="limit"
        @current-change="currentChange1"
        :total="$parent.total"
      >
      </el-pagination>
    </div>

    <div class="page" style="text-align: center">
      <el-pagination
        v-if="!showPage && listData.length != 0"
        layout="prev, pager, next"
        :pager-count="5"
        :current-page="$parent.page"
        :page-size="limit"
        @current-change="currentChange"
        :total="$parent.total"
      >
      </el-pagination>
    </div>

    <!-- 提示 -->
    <div class="tips" v-if="collect == true">
      {{ collect == true ? "收藏成功" : "收藏失败" }}
    </div>
    <!-- 错误提示 -->
    <div class="errorTips" v-if="errorTips == true">
      <i class="errorTips-icon"></i>
      <div class="errorTips-text">{{ errorTipsText }}</div>
    </div>
    <!-- 成功提示 -->
    <div class="successTips" v-if="successTips == true">
      <i class="successTips-icon"></i>
      <div class="successTips-text">{{ sucessTipsText }}</div>
    </div>

    <!-- 弹窗 -->
    <!-- <popup @handleClose="handleClose"></popup> -->
    <!-- 空 -->
    <div class="kong" v-if="listData.length == 0">
      <img :src="require('assets/images/kong.png')" alt="" />

      <div class="txt">{{ noText }}</div>
    </div>
  </div>
</template>

<script>
import popup from "components/goldenSentencel/dialog.vue";
// import SelectNotes from "components/idiom/selectNotes/SelectNotes";
import {
  oftenLibraryList,
  findOftenWord,
  findOftenLibraryType,
  collectOftenWord,
  collectList,
  findSelectedLib,
  oftenWordSearch,
} from "api/goldenSentenceIndex.js";
import { getToken } from "utils/auth";
import { addHotRecord } from "api/common";
import { templateDownload } from "api/search.js";
import { amendUpdate, settings } from "api/service";
import { debounce } from "utils/utils";
import qs from "qs";
// import defineSteps from "./driver/driver.js";
// import { isFirstLogin } from "api/checking";
export default {
  props: {
    listData: {
      type: Array,
    },
    labelId: {
      type: String,
    },
    stair: {
      type: String,
    },
    showPage: {
      type: Boolean,
    },
    noText: {
      type: String,
    },
    isPage: {},
  },
  data() {
    return {
      options: [
        {
          label: "footnotes",
          title: "脚注",
          type: 2,
          tips: "采纳金句内容时，自动标注内容来源标为脚注",
        },
        {
          label: "endnotes",
          title: "尾注",
          type: 1,
          tips: "采纳金句内容时，自动标注内容来源标为尾注",
        },
        {
          label: "no",
          title: "无",
          type: 0,
          tips: "采纳金句内容时，不标注内容来源",
        },
      ],
      // 分页显示雨隐藏
      // showPage: true,
      // 空数据
      isTxtshow: false,
      value: "",
      input: "",
      sortArr: [
        {
          title: "时间",
          sort: "desc",
          id: 1,
          colorSwitch: true,
        },
        {
          title: "热度",
          sort: "asc",
          id: 2,
          colorSwitch: false,
        },
        // {
        //   title: "引用时间",
        //   sort: "desc",
        //   id: 3,
        // },
        // {
        //   title: "引用频率",
        //   sort: "desc",
        //   id: 4,
        // },
      ],

      content: "基于特定语境公开信息数据的算法引擎，智能推文的内核",
      content1: "规范来源标注，自动插入为脚注、尾注",
      collect: false, //收藏
      total: null, //总条数
      page: 1, //页码
      limit: 10, //每页几条
      errorTips: false, //错误提示
      errorTipsText: "句库名称重复，请更换",
      successTips: false, //成功提示
      sucessTipsText: "",
      tableBarList: [],
      libStr: 99, //句库id
      sortType: 1, //排序类型
      sortVal: "desc", //排序类型
      isOften: 0, //收藏金句传1  默认是0
      oftenType: "", //主题id
      themeList: [], //主题列表
      themeIconShow: false, //控制图标显示  true 展开 false关闭
      collectionPage: false,
      turn: false, //自动智能推文
    };
  },
  //监控data中的数据变化
  watch: {
    // listDatas(val) {
    //   if (val.totalNum) {
    //     this.isTxtshow = false;
    //   } else {
    //     this.isTxtshow = true;
    //   }
    //   console.log(val.data);
    //   this.listData = val.data;
    //   this.total = val.totalNum;
    //   this.page = 1;
    //   this.backTop();
    // },
    listData(val) {
      this.listData = val;
    },

    isPage(val) {
      this.page = 1;
    },
  },
  components: {
    // popup,
    // SelectNotes,
  },
  methods: {
    // 回到顶部
    backTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    //是否显示引导
    async isShowDriver() {
      //console.log(this.$store.state.user_info);
      const data = {
        // userId:this.$store.state.user_info.id,
        pageName: "插件版核校",
      };
    },
    switchChange: debounce(async function () {
      this.turn = !this.turn;
      const autoRecommend = +this.turn;
      try {
        // eslint-disable-next-line
        SetAutomatic(autoRecommend);
      } catch (e) {
        console.log(e);
      }
      await amendUpdate({ autoRecommend });
      this.turnDelay = this.turn;
    }, 300),
    //获取用户设置
    async getSettings() {
      const res = await settings();
      if (res.code == 200) {
        this.turn = res.data.autoRecommend != null && +res.data.autoRecommend;
      }
    },
    async addHotRecord(md5) {
      const params = {
        md5,
      };
      const res = await addHotRecord(qs.stringify(params));
    },
    handleClose() {},
    async TemplateDownload(labelId) {
      const params = {
        labelId,
      };

      const res = await templateDownload(params);
    },

    handleSizeChange1(val) {
      if (this.$parent.checked != 2) {
        this.page = val;
        this.getFindOftenWord(this.$parent.themeId, this.$parent.libraryId);
      } else {
        this.limit = 10;
        this.$parent.buyDialog = true;
        // this.showPage = false;
        // this.$nextTick(() => {
        //   this.showPage = true;
        // });
      }
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getCollectList();
    },

    // 收藏
    collection: debounce(async function (item) {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      let params = {
        textMd5: item.textMd5 + "/" + this.stair,
      };
      const res = await collectOftenWord(params);
      if (res.code != 200) {
        this.$store.commit(
          "setLockClickText",
          this.$parent.currentClickItemVip
        );
        this.$bus.$emit("buyTipsLock");
        this.$message({
          type: "error",
          message: res.message,
        });
        return;
      }
      this.$message({
        type: "success",
        message: item.isMark ? "取消收藏！" : "收藏成功！",
        duration: "1000",
      });
      this.$emit("collectListClick");
      item.isMark = !item.isMark;
    }),
    // 搜索
    searchList() {
      this.page = 1;
      this.limit = 10;

      this.getFindOftenWord(this.oftenType);
    },
    // 复制全部文章
    copyName(item) {
      this.TemplateDownload(item.labelId);
      //  window.alert("插入内容 : " + item.oftenWord);
      // window.external.InsertLeft(item.oftenWord);
      let domUrl = document.createElement("input");
      domUrl.value = item.oftenWord;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      // this.$message({
      //   type:"success",
      //   message:"复制成功！",
      //   duration:"1000"
      // })
    },
    handleCopyName(item) {
      // window.alert("插入内容 : " + item.source+item.oftenWord);
      try {
        // eslint-disable-next-line
        InsertLeft(item.footNotes, item.nodeText);
      } catch (e) {
        console.log(e);
      }
    },
    // 复制文件名
    copyFileName(item) {
      let domUrl = document.createElement("input");
      domUrl.value = item;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        type: "success",
        message: "复制成功！",
        duration: "1000",
      });
    },
    // 获取通用、vip语库列表
    async getOftenLibraryList(id) {
      this.tableBarList = [];
      let formData = new FormData();
      formData.append("libType", id);
      const res = await oftenLibraryList(formData);
      if (res.code === 200) {
        this.tableBarList = res.data;
        if (id == 1) {
          let obj = {
            name: "收藏金句",
            id: 1,
          };
          this.tableBarList.unshift(obj);
        }
      } else {
        this.$message({
          type: "error",
          message: res.message,
          duration: "1000",
        });
      }
    },
    // 排序
    sortClick(item) {
      this.sortArr.forEach((item) => {
        item.colorSwitch = false;
      });
      item.colorSwitch = !item.colorSwitch;
      if (item.sort == "desc") {
        item.sort = "asc";
      } else {
        item.sort = "desc";
      }
      this.sortType = item.id;
      this.sortVal = item.sort;

      this.$emit("sortClick", {
        sortType: item.id,
        sortVal: item.sort,
      });
    },
    // 上一页
    currentChange1(val) {
      window.document.documentElement.scrollTop = 0;
      this.$emit("pageChange", val, 1);
      if (this.$parent.checked != 2) {
        this.page = val;
      } else {
        this.page = 1;
        this.$parent.buyDialog = true;
      }
    },

    // 收藏金句
    collectSentence: function () {
      this.isOften = 1;
      this.oftenType = "";
      this.libStr = "";
      this.input = "";
      this.value = "";
      this.page = 1;
      this.limit = 10;
      this.collectionPage = true;
      this.getCollectList();
    },
    // 核心金句
    coreSentence: function () {
      this.isOften = 0;
      this.value = "";
      this.input = "";
      this.page = 1;
      this.limit = 10;
      this.libStr = 99;
      this.srotType = 1;
      this.sortVal = "desc";
      this.inquire();
    },
    // 免费金句库
    freeSentence: function (id) {
      this.isOften = 0;
      this.value = "";
      this.input = "";
      this.page = 1;
      this.limit = 10;
      this.libStr = id;
      this.srotType = 1;
      this.sortVal = "desc";
      this.inquire();
    },

    // 收藏列表
    async getCollectList() {
      const params = {
        type: 2,
        limit: this.limit,
        page: this.page,
      };

      const res = await collectList(params);

      this.listData = res.data.data;

      this.total = res.data.total;
      if (this.listData == null) {
        this.listData = [];
        this.total = 0;
      }
    },

    currentChange(val) {
      window.document.documentElement.scrollTop = 0;
      this.$emit("pageChange", val, 2);
      // this.$emit("pageChange");
      // this.page = val;
      // this.getCollectList();
    },
  },
  created() {
    this.getSettings();
  },
  mounted: function () {
    window.collectSentence = this.collectSentence;
    window.coreSentence = this.coreSentence;
    window.freeSentence = this.freeSentence;
    this.isShowDriver();
  },
};
</script>

<style lang="scss" scoped>
.goldenSentenceIndex {
  position: relative;
  margin-bottom: 20px;
  .office-nav {
    @include flex-start;
    .switch,
    .history {
      @include flex-start;
      display: inline-flex;
      margin-left: 20px;
      cursor: pointer;
      .txt {
        font-size: 13px;
        font-weight: 500;
        color: #262e3e;
        margin-left: 5px;
      }
    }
    ::v-deep .dropDownList .el-dropdown-link {
      font-size: 13px;
    }
    .idiom-select-notes {
      margin-left: 20px;
      ::v-deep .el-dropdown-link {
        font-size: 13px;
      }
    }
  }
  &-search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px 0 29px;
    .searchBox {
      height: 47px;
      background: #ffffff;
      border: 1px solid #d9dee4;
      width: 100%;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      &-input {
        margin-left: 10px;
        height: 26px;
        width: 80%;
        ::v-deep .el-input {
          height: 26px;
          .el-input__inner {
            height: 26px;
            line-height: 26px;
            border: none;
          }
        }
      }
      &-btn {
        padding: 0 20px;
        height: 34px;
        background: #4587ff;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        // position: absolute;
        margin-right: 20px;
        &-icon {
          display: block;
          width: 16.2px;
          height: 16.2px;
          @include backgroundGroup(
            "../../assets/images/goldenSentence/search.png"
          );
        }
      }
    }
  }
  &-list {
    .list-theme {
      position: relative;
      margin: 10px 30px 0;
      height: 45px;
      line-height: 45px;
      background: #f8fafc;
      border-radius: 5px;
      overflow-y: hidden;
      display: flex;
      &-item {
        display: flex;
        position: relative;
        margin-left: 20px;
        top: 0px;
        &-text {
          width: 50px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .list-theme-img {
          width: 13px;
          height: 13px;
          margin-left: 6px;
          transform: translateY(15px);
        }
      }
      &-content {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 30px;
        height: 45px;
        flex-wrap: wrap;
        width: 83%;
        line-height: 45px;
        &-item {
          font-size: 14px;
          width: 115px;
          margin-right: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          cursor: pointer;
          @include ellipsis();
          &:hover {
            color: #4587ff;
          }
        }
      }

      .list-theme-icon {
        position: absolute;
        right: 30px;
        top: 15px;
        height: 14px;
        width: 14px;
        cursor: pointer;
      }
      .list-theme-icon-up {
        @include backgroundGroup(
          "../../assets/images/goldenSentence/theme-up.png"
        );
      }
      .list-theme-icon-down {
        @include backgroundGroup(
          "../../assets/images/goldenSentence/theme-down.png"
        );
      }
    }

    .list-title {
      display: flex;
      align-items: center;
      // padding: 20px 0px 0 0px;
      #LangeSelectNotes {
        margin-right: 25px;
      }
      &-item {
        display: flex;
        align-items: center;
        margin-right: 25px;
        .list-title-item-text {
          font-size: 14px;

          font-weight: 500;
          color: #333333;
        }
        .list-title-icon {
          width: 9px;
          height: 13px;
          margin-left: 6px;
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon.png"
          );
        }
        .up {
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon-blue-up.png"
          );
        }
        .down {
          @include backgroundGroup(
            "../../assets/images/goldenSentence/sortIcon-blue.png"
          );
        }
        .fontColor {
          color: #ff6900;
        }
      }
    }
    .list-content {
      margin-top: 13px;
      .list-content-item {
        padding: 0 0 30px 0;
        &:hover {
          background: #f8fafc;
        }
        .list-content-item-up {
          display: flex;
          line-height: 26px;
          padding-top: 16px;
          .list-content-item-add {
            width: 20px;
            height: 18px;
            @include backgroundGroup(
              "../../assets/images/goldenSentence/add.png"
            );
            margin-right: 11px;
            cursor: pointer;
            transform: translateY(4px);
          }
          .list-content-item-copy {
            width: 18px;
            height: 16px;
            @include backgroundGroup(
              "../../assets/images/goldenSentence/subTitle-icon.png"
            );
            margin-right: 11px;
            cursor: pointer;
            transform: translateY(4px);
          }
          .list-content-title {
            width: 99%;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
            cursor: pointer;
            .imgIcon {
              position: absolute;
              bottom: 15px;
              right: 15px;
              width: 20px;
            }
            .list-content-collection {
              display: inline-block;
              width: 17px;
              height: 16px;
              cursor: pointer;
            }
            .collection {
              @include backgroundGroup(
                "../../assets/images/goldenSentence/collection-red.png"
              );
            }
          }
          .list-content-form {
            margin-left: 35px;
            width: 90px;
            padding: 0 10px;
            color: #ffffff;
            background: #4587ff;
            border-radius: 50px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            text-align: center;
            @include ellipsis();
          }
        }
        .list-content-item-down {
          margin-left: 57px;
          margin-top: 20px;
          display: flex;
          &-icon {
            width: 14px;
            height: 14px;
            @include backgroundGroup(
              "../../assets/images/goldenSentence/subTitle-icon.png"
            );
            margin-right: 5px;
            cursor: pointer;
          }
          &-text {
            width: 475px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            @include ellipsis();
          }
        }
      }
    }
    .list-kong {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
    }
  }
  .footer-paging {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .paging-icon-left {
      width: 24px;
      height: 24px;
      @include backgroundGroup(
        "../../assets/images/goldenSentence/paging-left.png"
      );
      cursor: pointer;
    }
    .paging-text {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin: 0 10px;
    }
    .paging-icon-right {
      width: 24px;
      height: 24px;
      @include backgroundGroup(
        "../../assets/images/goldenSentence/paging-right.png"
      );
      cursor: pointer;
    }
    .right {
      @include backgroundGroup(
        "../../assets/images/goldenSentence/paging-blue-right.png"
      );
    }
    .left {
      @include backgroundGroup(
        "../../assets/images/goldenSentence/paging-blue-left.png"
      );
    }
  }
  .tips {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 111px;
    height: 46px;
    background: #7f7f7f;
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
  }
  // 报错提示
  .errorTips {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 47px;
    background: #ffffff;
    border: 1px solid #ff7668;
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ff7668;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    z-index: 10000;
    .errorTips-icon {
      width: 22px;
      height: 22px;
      background-image: url("../../assets/images/goldenSentence/error.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 9px;
    }
  }
  // 成功提示
  .successTips {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 46px;
    background: #4587ff;
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    z-index: 10000;
    .successTips-icon {
      width: 21px;
      height: 21px;
      background-image: url("../../assets/images/goldenSentence/success.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 9px;
    }
  }

  // 空
  .kong {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      height: 150px;
      width: 150px;
    }

    .txt {
      margin-top: 30px;
      color: #999;
    }
  }
}
</style>
