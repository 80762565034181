var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goldenSentenceIndex"},[_c('div',{staticClass:"goldenSentenceIndex-list"},[_c('div',{staticClass:"list-title"},_vm._l((_vm.sortArr),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$parent.isMark),expression:"!$parent.isMark"}],key:index,staticClass:"list-title-item",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.sortClick(item)}}},[_c('div',{staticClass:"list-title-item-text",class:item.colorSwitch == true ? 'fontColor' : ''},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('i',{staticClass:"list-title-icon up",class:item.sort == 'asc' ? 'up' : 'down'})])}),0),_c('div',{staticClass:"list-content"},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"list-content-item"},[_c('div',{staticClass:"list-content-item-up"},[_c('i',{staticClass:"list-content-item-copy",on:{"click":function () {
                _vm.copyFileName(item.nodeText);
                _vm.addHotRecord(item.textMd5);
              }}}),_c('div',{staticClass:"list-content-title"},[_vm._v(" "+_vm._s(index + 1)+". "),_c('span',{staticStyle:{"word-break":"normal"},domProps:{"innerHTML":_vm._s(item.nodeText)}}),_c('img',{staticClass:"list-content-collection",attrs:{"src":item.isMark
                  ? require('assets/images/goldenSentence/collection-red.png')
                  : require('assets/images/goldenSentence/collection.png'),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.collection(item)}}})])]),(item.footNotes)?_c('div',{staticClass:"list-content-item-down"},[_c('i',{staticClass:"list-content-item-down-icon",on:{"click":function () {
                _vm.addHotRecord(item.textMd5);
                _vm.copyFileName(item.footNotes);
              }}}),_c('div',{staticClass:"list-content-item-down-text"},[_vm._v(_vm._s(item.footNotes))])]):_vm._e()])}),0)]),_c('div',{staticClass:"footer-paging"},[(_vm.listData.length != 0 && _vm.showPage)?_c('el-pagination',{attrs:{"layout":"prev, pager, next","pager-count":5,"current-page":_vm.$parent.page,"page-size":_vm.limit,"total":_vm.$parent.total},on:{"current-change":_vm.currentChange1}}):_vm._e()],1),_c('div',{staticClass:"page",staticStyle:{"text-align":"center"}},[(!_vm.showPage && _vm.listData.length != 0)?_c('el-pagination',{attrs:{"layout":"prev, pager, next","pager-count":5,"current-page":_vm.$parent.page,"page-size":_vm.limit,"total":_vm.$parent.total},on:{"current-change":_vm.currentChange}}):_vm._e()],1),(_vm.collect == true)?_c('div',{staticClass:"tips"},[_vm._v(" "+_vm._s(_vm.collect == true ? "收藏成功" : "收藏失败")+" ")]):_vm._e(),(_vm.errorTips == true)?_c('div',{staticClass:"errorTips"},[_c('i',{staticClass:"errorTips-icon"}),_c('div',{staticClass:"errorTips-text"},[_vm._v(_vm._s(_vm.errorTipsText))])]):_vm._e(),(_vm.successTips == true)?_c('div',{staticClass:"successTips"},[_c('i',{staticClass:"successTips-icon"}),_c('div',{staticClass:"successTips-text"},[_vm._v(_vm._s(_vm.sucessTipsText))])]):_vm._e(),(_vm.listData.length == 0)?_c('div',{staticClass:"kong"},[_c('img',{attrs:{"src":require('assets/images/kong.png'),"alt":""}}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.noText))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }